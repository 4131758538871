import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter as Router } from 'react-router-dom';

import './index.css';
import { store, persistor } from 'app/store';
import { PersistGate } from 'redux-persist/integration/react';
import * as serviceWorker from './serviceWorker';
import Container from './Container';
import AppProviders from './appProviders';
import { ErrorFallback } from 'components/ErrorBoundary';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppProviders>
            <ToastContainer />
            <HelmetProvider>
              <ErrorBoundary
                FallbackComponent={(props) => (
                  <ErrorFallback {...props} isParent={true} />
                )}
              >
                <Container />
              </ErrorBoundary>
            </HelmetProvider>
          </AppProviders>
        </PersistGate>
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
