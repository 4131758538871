import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { ConsentState } from 'appConstants';
import AsyncButton from 'components/AsyncButton';
import CheckboxComponent from 'components/Checkbox';
import Modal from 'components/Modal';
import SuccessModal from 'components/shared/SuccessModal';
import { fetchProfile } from 'features/login/redux';
import { fetchOrganisation } from 'features/SPOReport/redux';
import { ReactElement, useState } from 'react';
import { Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import { useAppDispatch } from 'app/hooks';
import rehypeSlug from 'rehype-slug';
import remarkToc from 'remark-toc';
import { ConsentStateItem } from 'types';
import { updateConsentState } from '../redux';
import * as S from '../../OrganizationDetails/OrganizationConsents/styled';

export interface ConfirmationDialogRawProps {
  id: string;
  keepMounted: boolean;
  value: ConsentStateItem;
  open: boolean;
  onClose: (value?: string) => void;
  currentIndex: number;
  setCurrentIndex: Function;
  handleAccept: Function;
  handleDecline: Function;
  consentLength?: number;
}

function ConsentModal(props: ConfirmationDialogRawProps) {
  const { onClose, value: valueProp, open, currentIndex, setCurrentIndex, handleDecline, handleAccept, consentLength, ...other } = props;
  const [value, setValue] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [isAgreed, setIsAgreed] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    handleAccept();
  };

  const confirmFooter: ReactElement = (
    <div className='row'>
      <div className='col' style={{ minWidth: '8em' }}>
        <AsyncButton
          messageId='continue'
          onClick={handleCloseSuccessModal}
          isBlue
          testId='continue'
        />
      </div>
    </div>
  );

  function setShowModal() {}

  const handleCancel = () => {
    onClose();
    setChecked(false);
  };

  const handleOk = async () => {
    setShowSuccessModal(true);
    onClose(valueProp?.description);
    setChecked(false);
    setIsAgreed(true);
    await dispatch(updateConsentState(valueProp.id, ConsentState.Agreed));
  };

  const handleDisagree = async () => {
    setIsAgreed(false);
    setShowSuccessModal(true);
    await dispatch(updateConsentState(valueProp.id, ConsentState.Rejected));
  };

  const handleAgree = async () => {
    setIsAgreed(true);
    setShowSuccessModal(true);
    await dispatch(updateConsentState(valueProp.id, ConsentState.Agreed));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const renderContent = () => {
    return (
      valueProp && (
        <ReactMarkdown
          children={valueProp?.description}
          remarkPlugins={[remarkToc]}
          rehypePlugins={[rehypeSlug]}
        />
      )
    );
  };

  return (
    <>
      <S.StyledDialog
        maxWidth='sm'
        aria-labelledby='confirmation-dialog-title'
        data-testid='consent-modal'
        data-locale={valueProp?.locale}
        data-consentLength={consentLength}
        data-type={valueProp.testType}
        open={open}
        {...other}
      >
        <S.StyledDialogTitle id='confirmation-dialog-title'>
          <FormattedMessage id='GeneralTerms' />
        </S.StyledDialogTitle>
        <S.StyledDialogContent>
          <S.MarkDownContainer>{renderContent()}</S.MarkDownContainer>
          {valueProp?.isMandatory ? (
            <div className='checkbox' style={{ margin: 'unset' }}>
              <CheckboxComponent
                testId='consent-checkbox'
                handleChange={() => {
                  setChecked(!checked);
                }}
                checked={checked}
              />
              <S.ConsentNote>
                <FormattedMessage id='AcceptConditions' />
              </S.ConsentNote>
            </div>
          ) : (
            <S.ConsentNote>
              <FormattedMessage id='consentDesc' />
            </S.ConsentNote>
          )}
        </S.StyledDialogContent>
        <S.StyledDialogActions className={valueProp?.isMandatory ? 'end' : ''}>
          {valueProp?.isMandatory ? (
            <S.StyledAsyncButton
              testId='consent-confirm-button'
              onClick={handleOk}
              isBlue
              disabled={!checked}
              messageId='continue'
            />
          ) : (
            <>
              <S.StyledAsyncButton
                testId='consent-disagree-button'
                onClick={handleDisagree}
                isBlue
                className='disagree'
                messageId='IDisagree'
              />
              <S.StyledAsyncButton
                onClick={handleAgree}
                isBlue
                messageId='IAgree'
              />
            </>
          )}
        </S.StyledDialogActions>
      </S.StyledDialog>
      {valueProp?.isMandatory ? (
        <SuccessModal
          show={showSuccessModal}
          successMessageId2={'TermsPage'}
          successTitle={'CanAccess'}
          setShow={setShowSuccessModal}
          footer={confirmFooter}
          handleClose={handleCloseSuccessModal}
          style={{ textAlign: 'left' }}
        />
      ) : isAgreed ? (
        <SuccessModal
          show={showSuccessModal}
          successMessageId={'canRevoke'}
          successMessageId2={'contactEmail1'}
          successMessageId3={'contactEmail2'}
          successMessageId4={'contactEmail3'}
          successTitle={'continueToAccess'}
          setShow={setShowSuccessModal}
          footer={confirmFooter}
          handleClose={handleCloseSuccessModal}
          style={{ textAlign: 'left' }}
        />
      ) : (
        <SuccessModal
          show={showSuccessModal}
          successMessageId={'canRevoke'}
          successMessageId2={'notShare'}
          successMessageId3={'giveIn6Month'}
          successTitle={'continueToAccess'}
          setShow={setShowSuccessModal}
          footer={confirmFooter}
          handleClose={handleCloseSuccessModal}
          style={{ textAlign: 'left' }}
        />
      )}
    </>
  );
}

export default ConsentModal;
