import type { GenericHttpResult } from '@agunity/api-v4';
import { apiService } from 'api/apiService';
import { fetchProfile } from 'features/login/redux';

export const submitFeedback = (data) => (async (dispatch: Function, getState: Function): Promise<GenericHttpResult<void>> => {
  const response = await apiService.uiV10FeedbackCreate(data);
  dispatch(fetchProfile());
  return response;
});

export const submitNoFeedback = () => (async (dispatch: Function, getState: Function): Promise<GenericHttpResult<void>> => {
  const response = await apiService.uiV10NoFeedbackCreate();
  dispatch(fetchProfile());
  return response;
});
