import { appEnv } from 'api/environments';
import { useAppSelector } from 'app/hooks';
import { selectVersion } from 'features/login/redux';
import { version } from '../../../../package.json';
import * as S from './styled';

const VersionUI = ({ dark }: { dark?: boolean }) => {
  const { environmentName = '', assemblyVersion = '0.0.0' } = useAppSelector(selectVersion) || {};

  return (
    <S.Container className={dark ? 'dark' : ''} data-testid='version-container'>
      <S.Text data-testid='version-ui'>
        UI: {appEnv} {version}
      </S.Text>
      <S.Text data-testid='version-api'>
        API: {environmentName} {assemblyVersion}
      </S.Text>
    </S.Container>
  );
};

export default VersionUI;
