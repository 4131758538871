import {
  COMMENT_READ_REASON,
  PERMISSIONS,
  PLAN_STATUS,
  USER_CATEGORY,
  WRAPPER_STATUS,
} from 'appConstants';
import isURL from 'validator/lib/isURL';
import { LanguageContext } from 'components/Language/Context';
import { useContext } from 'react';
import { createIntl, createIntlCache } from 'react-intl';
import { ICommentSummary, PlanWrapperSummary, Profile } from 'types';

export const isValidEmail = (val: string) => {
  const isSamsungBrowser = navigator.userAgent.match(
    /SAMSUNG|Samsung|samsung|SGH-[I|N|T]|GT-[I|N]|SM-[A|N|P|T|Z]|SHV-E|SCH-[I|J|R|S]|SPH-L/i
  );
  if (!isSamsungBrowser) {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEmail.test(val);
  } else {
    const split = val.split('@');
    if (split.length !== 2) return false;
    if (split[1].indexOf('.') < 2) return false;
    return true;
  }
};

export const isPasswordMatch = (val1: string, val2: string) => {
  return val1 === val2;
};

export const fdate = (s: string | number | Date) => {
  const x = new Date(s);
  if (!x || !s) return '';
  const d = `${x.getDate().toString().padStart(2, '0')}/${(x.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${x.getFullYear()}`;
  return d;
};

export const fdate2 = (s: string | number | Date) => {
  const x = new Date(s);
  if (!x || !s) return '';
  const d = `${x.getUTCFullYear()}-${(x.getUTCMonth() + 1)
    .toString()
    .padStart(2, '0')}-${x.getDate().toString().padStart(2, '0')}`;
  return d;
};

export const clone = (toClone: any) => {
  return JSON.parse(JSON.stringify(toClone));
};

export const statusColor = (status: number): string => {
  switch (status) {
    case PLAN_STATUS.Approved:
      return 'green';
    case PLAN_STATUS.Draft:
      return 'orange';
    case PLAN_STATUS.Deleted:
      return 'var(--error-color)';
    default:
      return 'blue';
  }
};

export const numericFormat = (date, time?: string) => {
  if (!date) return;
  let newDate = new Date(date);
  let day: any = newDate.getDate();
  let month: any = newDate.getMonth() + 1;
  let year: any = newDate.getFullYear();
  //add an extra zero in front of day and month if it's single digits
  if (day < 10) day = `0${day}`;
  if (month < 10) month = `0${month}`;
  if (time) return new Date(`${year}-${month}-${day}T23:59:59Z`).toString();
  return `${year}-${month}-${day}`;
};

export const FormatDate = (date): string => {
  // const { messages } = useContext(LanguageContext);
  if (!date) return '';

  date = new Date(date);
  var monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();
  var hours = date.getHours();
  var mins = date.getMinutes();
  var secs = date.getSeconds();

  if (day < 10) day = `0${day}`;
  if (hours < 10) hours = `0${hours}`;
  if (mins < 10) mins = `0${mins}`;
  if (secs < 10) secs = `0${secs}`;

  return `${day} ${monthNames[monthIndex]} ${year}`;

  // return `${day} ${messages[monthNames[monthIndex]]} ${year}`;
};

const cache = createIntlCache();
export function CommaFormatted(amount) {
  var defaultLocale = window?.localStorage.getItem('locale') || 'en';
  const intl = createIntl(
    {
      locale: defaultLocale,
      messages: {},
    },
    cache
  );
  if (isNaN(amount)) {
    return 0;
  } else {
    return intl.formatNumber(amount);
  }
}

export function RemovePreFilledZeroInInputs(data) {
  if (data === undefined || data === null) {
    return '';
  } else {
    return CommaFormatted(data);
  }
}

export const dataToBase64 = (
  dataBlob: File | Blob,
  returnType: 'base64' | 'dataURL'
) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(dataBlob);
    fileReader.onerror = () => reject(fileReader.error);
    fileReader.onload = () => {
      const base64Part = (fileReader.result as string)
        .replace('data:', '')
        .replace(/^.+,/, '');
      return returnType === 'dataURL'
        ? resolve(fileReader.result)
        : resolve(base64Part);
    };
  });
};

export const isValidURL = (str: string): boolean => {
  const options: any = {
    protocols: ['http', 'https', 'www'],
    require_tld: true,
    require_protocol: false,
    require_host: true,
    require_valid_protocol: true,
    allow_underscores: true,
    host_whitelist: false,
    host_blacklist: false,
    allow_trailing_dot: false,
    allow_protocol_relative_urls: false,
    disallow_auth: false,
  };
  return isURL(str, options);
};

/**
 * Make Text safe to export in a CSV.
 */
export const csvSafeText = (text: string) => {
  if (!text || !text.length) return null;
  const whiteSpaceRegex = /[,;\r\n\t]/gi;
  const quotesRegex = /"/gi;
  return text.replace(whiteSpaceRegex, ' ').replace(quotesRegex, "'");
};

export const validateFileSize = (file) => file?.size <= 5242880; // 5242880 Bytes (in binary) === 5 MB

export const validateFileFormat = (file, format = 'image') => {
  const excel =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  const msword =
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword';
  if (
    format === 'image' &&
    file.type.split('/').pop() !== 'png' &&
    file.type.split('/').pop() !== 'jpeg' &&
    file.type.split('/').pop() !== 'jpg'
  ) {
    return false;
  } else if (
    format === 'doc' &&
    file.type.split('/').pop() !== 'pdf' &&
    file.type.split('/').pop() !== excel &&
    file.type.split('/').pop() !== msword
  )
    return false;
  else if (
    format === 'file' &&
    file.type.split('/').pop() !== 'png' &&
    file.type.split('/').pop() !== 'jpeg' &&
    file.type.split('/').pop() !== 'jpg' &&
    file.type.split('/').pop() !== 'pdf' &&
    file.type.split('/').pop() !== excel &&
    file.type.split('/').pop() !== msword
  )
    return false;
  return true;
};

export const getMonthFromDate = (date) => {
  const newDate = new Date(date);
  return newDate.getMonth() + 1;
};

export const getMonthName = (month: number | string) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return monthNames[Number(month) - 1];
};

export const truncateFileNames = (name: string) => {
  if (!name) return '';
  const split = name.split('.');
  return `${split[0].slice(0, 70)}.${split[1]}`;
};

export function whichBrowser() {
  let userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'chrome';
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'firefox';
  } else if (userAgent.match(/safari/i)) {
    browserName = 'safari';
  } else if (userAgent.match(/opr\//i)) {
    browserName = 'opera';
  } else if (userAgent.match(/edg/i)) {
    browserName = 'edge';
  } else {
    browserName = 'No browser detection';
  }

  return browserName;
}

/**
 * This function is only valid under
 * the context of: detectUserCategory()
 */
const melOrFs = (permissions) => {
  if (
    permissions.includes(PERMISSIONS.QUANTITATIVE_REVIEW) &&
    permissions.includes(PERMISSIONS.QUALITATIVE_REVIEW)
  ) {
    return USER_CATEGORY.MEL;
  }
  return USER_CATEGORY.FS;
};

/**
 * THIS CATEGORY IS ONLY VALID UNDER THE CONTEXT
 * OF A PARTICULAR WRAPPER
 */
export function detectUserCategory(
  wrapperSummary: PlanWrapperSummary,
  permissions: Profile['permissions']
) {
  let category: USER_CATEGORY | null = null;
  const { status: wrapperStatus, canEdit } = wrapperSummary;
  if (wrapperStatus === WRAPPER_STATUS.QuantitativeReview && canEdit) {
    category = USER_CATEGORY.MEL;
  } else if (
    (wrapperStatus === WRAPPER_STATUS.QualitativeReview && canEdit) ||
    (wrapperStatus === WRAPPER_STATUS.POReview && !canEdit)
  ) {
    category = melOrFs(permissions);
  } else {
    category = USER_CATEGORY.PO;
  }
  return category;
}

export interface IAreCommentsRead {
  read: boolean;
  reason: null | COMMENT_READ_REASON;
}

export function areCommentsRead(
  commentSummary: ICommentSummary
): IAreCommentsRead {
  const { total, resolved, readUnresolved } = commentSummary;
  let unreadComments: number = 0;
  unreadComments = total - resolved - readUnresolved;
  let commentStatus: IAreCommentsRead = {
    read: false,
    reason: null,
  };
  if (total) {
    if (unreadComments === 0) {
      commentStatus.read = true;
      commentStatus.reason = COMMENT_READ_REASON.ALL_READ;
    } else if (resolved === total) {
      commentStatus.read = true;
      commentStatus.reason = COMMENT_READ_REASON.ALL_RESOLVED;
    }
  }
  return commentStatus;
}

/**
 * Returns a Promise that resolves after a specified delay.
 * @param ms - The number of milliseconds to wait before resolving. The default value is 500ms.
 * @returns A Promise that resolves after the specified delay.
 */
export function timeout(ms: number | undefined = 500) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function capitalizeFirstLetter(string = '') {
  return string.charAt(0).toUpperCase() + string.slice(1);
}