import { ThunkArgs, IResponse } from 'types';
import { createSlice } from '@reduxjs/toolkit';
import { apiService } from 'api/apiService';
import { RootState } from 'app/store';

import type { 
  ApiAvailableExtSurvey,
  ApiExtSurvey,
  ApiExtSurveyDefinitionOut,
  ApiExtSurveyFullResult,
  ApiExtSurveyTargetState,
  GenericHttpResult,
} from '@agunity/api-v4';

export const hot = '../features/Survey/redux.ts';

interface SurveyState {
  showSurveyPreviewModal: 'stage 1' | 'stage 2' | false;
  previewLanguage: string;
  selectedSurvey: any;
  previewOrgType: 'HL' | 'SPO';
  isNewSurveyExists: boolean;
  newSurveyUpload: any;
  addSurveyInstance: any;
  refetchSurvey: boolean;
  isOpenConfirmModal: boolean;
  isShowSurveyComplete: boolean;
  surveyResult: string;
  poProxyOrgId: number;
}

const initialState: SurveyState = {
  showSurveyPreviewModal: false,
  previewLanguage: 'English',
  selectedSurvey: null,
  previewOrgType: 'HL',
  isNewSurveyExists: false,
  newSurveyUpload: null,
  addSurveyInstance: null,
  refetchSurvey: false,
  isOpenConfirmModal: false,
  isShowSurveyComplete: false,
  surveyResult: '',
  poProxyOrgId: -1
};

export const counterSlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    resetSurveyState: () => initialState,
    showSurveyPreviewModal: (state, action) => {
      state.showSurveyPreviewModal = action.payload;
    },
    previewOrgType: (state, action) => {
      state.previewOrgType = action.payload;
    },
    previewLanguage: (state, action) => {
      state.previewLanguage = action.payload;
    },
    selectedSurvey: (state, action) => {
      state.selectedSurvey = action.payload;
    },
    isNewSurveyExists: (state, action) => {
      state.isNewSurveyExists = action.payload;
    },
    newSurveyUpload: (state, action) => {
      state.newSurveyUpload = action.payload;
    },
    addSurveyInstance: (state, action) => {
      state.addSurveyInstance = action.payload;
    },
    setRefetchSurvey: (state, action) => {
      state.refetchSurvey = action.payload;
    },
    setIsOpenConfirmModal: (state, action) => {
      state.isOpenConfirmModal = action.payload;
    },
    setIsShowSurveyComplete: (state, action) => {
      state.isShowSurveyComplete = action.payload;
    },
    setSurveyResult: (state, action) => {
      state.surveyResult = action.payload;
    },
    setPOProxyOrgId: (state, action) => {
      state.poProxyOrgId = action.payload;
    },
  },
});

export const {
  showSurveyPreviewModal,
  previewOrgType,
  previewLanguage,
  selectedSurvey,
  isNewSurveyExists,
  newSurveyUpload,
  addSurveyInstance,
  resetSurveyState,
  setRefetchSurvey,
  setIsOpenConfirmModal,
  setIsShowSurveyComplete,
  setSurveyResult,
  setPOProxyOrgId
} = counterSlice.actions;

export const selectShowSurveyPreviewModal = (state: RootState) =>
  state.survey.showSurveyPreviewModal;
export const selectPreviewLanguage = (state: RootState) =>
  state.survey.previewLanguage;
export const selectPreviewOrgType = (state: RootState) =>
  state.survey.previewOrgType;
export const selectSelectedSurvey = (state: RootState) =>
  state.survey.selectedSurvey;
export const selectIsNewSurveyExists = (state: RootState) =>
  state.survey.isNewSurveyExists;
export const selectNewSurveyUpload = (state: RootState) =>
  state.survey.newSurveyUpload;
export const selectAddSurveyInstance = (state: RootState) =>
  state.survey.addSurveyInstance;
export const selectIsOpenSurveyConfirmModal = (state: RootState) =>
  state.survey.isOpenConfirmModal;
export const selectIsShowSurveyComplete = (state: RootState) =>
  state.survey.isShowSurveyComplete;
export const selectSurveyResult = (state: RootState) =>
  state.survey.surveyResult;
export const selectUserOrg = (state: RootState) => (state?.spo?.organisation);
export const selectPOProxyOrgId = (state: RootState) => (state?.survey.poProxyOrgId);

export const fetchSingleSurvey =
  (instanceId: number, orgId: number) =>
    async (): Promise<GenericHttpResult<ApiExtSurvey>> => {
      const response = await apiService.extSurveyV11ItemInstanceDetail(instanceId, orgId);
      return response;
    };

export const SaveSurvey =
  (body: any) =>
    async (): Promise<GenericHttpResult<number>> => {
      const response = await apiService.extSurveyV10ItemCreate(body);
      return response;
    };

export const editSurvey =
  (body: any) =>
    async (): Promise<GenericHttpResult<void>>=> {
      const response = await apiService.extSurveyV10ItemPartialUpdate(body);
      return response;
    };

export const deleteSurvey =
  (surveyId: number) =>
    async (): Promise<GenericHttpResult<void>> => {
      const response = await apiService.extSurveyV10ItemDelete(surveyId);
      return response;
    };

export const fetchSurveyItems =
  () =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<ApiAvailableExtSurvey[]>> => {
      const response = await apiService.extSurveyV11AvailableList();
      return response;
    };

export const getSurveysAndInstances =
  () =>
    async (): Promise<GenericHttpResult<ApiExtSurveyDefinitionOut[]>> => {
      const response = await apiService.extSurveyV11ItemsList();
      return response;
    };

export const getInstanceData =
  (instanceId: any) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<ApiExtSurveyFullResult[]>> => {
      const response = await apiService.extSurveyV10ResultListDetail(instanceId);
      return response;
    };

export const saveSurveyResult =
  (body: any) =>
    async (): Promise<GenericHttpResult<void>> => {
      const response = await apiService.extSurveyV10ResultCreate(body);
      return response;
    };

export const saveSurveyInstance =
  (body: any) =>
    async (): Promise<GenericHttpResult<number>> => {
      const response = await apiService.extSurveyV10InstanceCreate(body);
      return response;
    };
export const saveSurveyInstanceDraft =
  (data: any) =>
    async (): Promise<GenericHttpResult<void>>=> {
      const response = await apiService.extSurveyV11InstancePartialUpdate(data.id, data);
      return response;
    };
export const getSurveyInstances =
  (body: any) =>
    async () => {
      const response = await apiService.fairtradeOrganizationsV10ExpandInfoCreate(body);
      return response;
    };
export const getInfoFromProductTree =
  (documentKey: string) =>
    async (): Promise<GenericHttpResult<Blob>> => {
      const response = await apiService.documentV10DownloadDetail(documentKey);
      return response;
    };

export const getSurveyDefinition =
  (surveyId: string | Number, locale: string) =>
    async (): Promise<GenericHttpResult<ApiExtSurvey>> => {
      const response = await apiService.extSurveyV10ItemDetail(surveyId as number, locale);
      return response;
    };

export const deleteSurveyInstances =
  (instanceId: number) =>
    async (): Promise<GenericHttpResult<void>> => {
      const response = await apiService.extSurveyV10InstanceDelete(instanceId);
      return response;
    };

export const archiveSurveyOrInstances =
  (itemType: number, itemId: number, inactive: number) =>
    async (): Promise<GenericHttpResult<void>> => {
      const response = await apiService.uiV10ActiveStatusPartialUpdate(itemType, itemId, inactive);
      return response;
    };

export const getTargetList =
  (id: number) =>
    async (): Promise<GenericHttpResult<ApiExtSurveyTargetState[]>> => {
      const response = apiService.extSurveyFaitradeV10TargetDetail(id);
      return response;
    };

export default counterSlice.reducer;
