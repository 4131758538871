import { FormattedMessage } from 'react-intl';
import { useContext, useEffect, useState } from 'react';
import { useAppDispatch } from 'app/hooks';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { fetchLanguages, fetchSelectedLocaleData, selectedLanguages } from 'features/Localisation/redux';
import { LanguageContext } from './Context';
import { Locale } from 'types';
import { setLocale as setProfileLocale } from './redux';
import * as S from './styled';
import * as H from '../../features/page/components/Headbar.styled';
import dropdownIcone from 'assets/icons/dropdownIcone.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  })
);

const _flagMap = {
  en: 'us',
  es: 'mx',
  fr: 'cd',
  pt: 'br'
};

const defaultLocales = [
  {
    id: 0,
    locale: 'en',
    name: 'English',
    flag: `assets/flags/${_flagMap['en']}.png`
  },
  {
    id: 1,
    locale: 'es',
    name: 'Spanish',
    flag: `assets/flags/${_flagMap['es']}.png`
  },
  {
    id: 2,
    locale: 'fr-FR',
    name: 'French',
    flag: `assets/flags/${_flagMap['fr']}.png`
  },
  {
    id: 3,
    locale: 'pt-PT',
    name: 'Portuguese',
    flag: `assets/flags/${_flagMap['pt']}.png`
  }
];

export default function LanguageSelector({
  type,
  handleLanguageChange,
  value,
  defaultValue,
  testId
}: any): JSX.Element | null {
  const [locales, setLocales] = useState<Locale[]>();
  const { setLocale, locale, setLangLocales } = useContext(LanguageContext);
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const onFetchLanguages = async () => {
    try {
      const response = await dispatch(fetchLanguages());
      if (response && response?.data) {
        setLocales(response.data as Locale[]);
        setLangLocales(response.data);
      }
    } catch (error) {
      const ps = defaultLocales.map(async (l) => {
        const loc = l.locale.substring(0, 2);
        const flag = await import(`assets/flags/${_flagMap[loc]}.png`);
        return { ...l, flag: flag.default };
      });
      const locales: Locale[] = await Promise.all(ps);
      setLocales(locales);
      setLangLocales(locales);
    }
  };

  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      setLocales(defaultLocales);
      setLangLocales(defaultLocales);
      return;
    }

    onFetchLanguages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // useEffect(()=>{
  //   const fetch = async () => {
  //     const selectLanguage = locales?.find(lang => lang.locale === locale);
  //     dispatch(selectedLanguages(selectLanguage));
  //     await dispatch(fetchSelectedLocaleData());
  //   };
  //   fetch();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[locale, dispatch]);

  const handleChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const l: string = (event?.target?.value as string) || 'en';
    const selectLanguage = locales?.find(lang => lang.locale === l);
    setLocale(l);
    await dispatch(setProfileLocale(l));
    dispatch(selectedLanguages(selectLanguage));
    await dispatch(fetchSelectedLocaleData());
  };

  if (!locales || locales.length === 0) return null;

  const getLocalesOptions =
    locales &&
    locales.map(({ id, locale, name, flag }) => {
      return (
        <MenuItem style={{ fontSize: '0.8em' }} key={id} value={locale}>
          {/* <S.Flag src={flag} /> */}
          {name}
        </MenuItem>
      );
    });

  return (
    <>
      {type ? (
        <FormControl fullWidth variant='outlined' data-testid={testId}>
          <Select
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }}
            IconComponent={(props) => <img {...props} src={dropdownIcone} />}
            data-testid='lang-select'
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={value || locale}
            onChange={(e) => handleLanguageChange(e.target.value)}
            style={{
              fontSize: '0.8em',
              height: '2.5em'
            }}
            displayEmpty={!!defaultValue}
          >
            {defaultValue && (
              <MenuItem value={''} disabled>
                <em>{defaultValue}</em>
              </MenuItem>
            )}
            {getLocalesOptions}
          </Select>
        </FormControl>
      ) : (
        <S.LocalesContainer>
          <FormControl
            variant='outlined'
            className={classes.formControl}
            style={{ width: '189px' }}
          >
            <H.MenuItem id='demo-simple-select-label'>
              <FormattedMessage id='language' />
            </H.MenuItem>
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                getContentAnchorEl: null
              }}
              IconComponent={(props) => <img {...props} src={dropdownIcone} />}
              data-testid='lang-select'
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={locales && locales.length > 0 ? locale : ''}
              onChange={handleChange}
              style={{ fontSize: '0.8em', height: '2.5em' }}
            >
              {getLocalesOptions}
            </Select>
          </FormControl>
        </S.LocalesContainer>
      )}
    </>
  );
}
