import { ContentType, GenericHttpClient, GenericHttpResult, HttpMethod, HttpResContentKind, RequestParams } from '@agunity/api-v4';
import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
  GenericAbortSignal,
  Method
} from 'axios';
import { apiUrl } from './environments';
import { store } from 'app/store';
import { inProgress, selectInProgress, status } from 'api';

export type FairTradeRequestParams = RequestParams & { signal?: GenericAbortSignal };

/**
 * Http request axios
 *
 * This should be moved to the project where it is needed.
 * This would allow us to remove the Axios lib from the dependencies (leave in dev for testing)
 */

export class AxiosHttpClient implements GenericHttpClient {
  onError?: <T>(err: Error) => GenericHttpResult<T>;

  request<T>({
    url,
    method,
    body,
    query,
    type,
    format,
    params,
  }: {
    url: string;
    method: HttpMethod;
    body?: any;
    query?: any;
    type?: ContentType;
    format?: HttpResContentKind;
    params: FairTradeRequestParams;
  }): Promise<GenericHttpResult<T>> {
    const options: AxiosRequestConfig = {
      method: method.toLowerCase() as Method,
      headers: params.headers,
      url: apiUrl + url,
      data: body,
      decompress: false,
      signal: params?.signal,
    };

    if (format) {
      switch (format) {
        case 'json':
          options.responseType = 'json';
          break;
        case 'blob':
          options.responseType = 'blob';
          break;
        case 'other':
          options.responseType = 'text'; // or 'arraybuffer' or any other valid responseType
          break;
        case 'formData':
          // Axios doesn't support 'formData' as a responseType, you might need to handle this case differently
          break;
        case 'document':
          options.responseType = 'document';
          break;
        default:
          options.responseType = 'json';
          break;
      }
    }

    const setStatus = (type: 'idle' | 'loading' | 'failed') => {
      const skipAPIStatusUpdate = params.headers && params.headers['skipAPIStatusUpdate'] === 'true';
      if (store && !skipAPIStatusUpdate) store.dispatch(status(type));
    };

    if (store) {
      setStatus('loading');
      store.dispatch(inProgress(selectInProgress(store.getState()) + 1));
    }
    return axios
      .request(options)
      .then((resp: AxiosResponse<T>) => {
        const result: GenericHttpResult<T> = {
          data: resp.data,
          status: resp.status,
          headers: resp.headers as { [key: string]: string },
        };
        return result;
      })
      .catch(async (error: AxiosError) => {
        if (store) {
          setStatus('failed');
          if (error.message.indexOf('JSON input')) setStatus('idle');
        }
        if (error.request.responseType === 'blob' && error.response?.data instanceof Blob) {
          error.response.data = JSON.parse(await error.response.data.text()); // Convert Blob to JSON
        }
        if(axios.isCancel(error)) {
          console.log('Network Request Cancelled', error);
        }
        if (this.onError) {
          return this.onError<T>(error);
        } else {
          throw error;
        }
      }).finally(() => {
        if (store) {
          store.dispatch(inProgress(selectInProgress(store.getState()) - 1));
          if (selectInProgress(store.getState()) === 0) {
            setStatus('idle');
          };
        }
      });
  }
}
