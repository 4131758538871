import { createSlice } from '@reduxjs/toolkit';
import type {
  ApiCODImpact,
  ApiCODImpactStatus,
  ApiCODImpactSummaryItem,
  ApiCODImpactSummaryRequest,
  ApiFTConsentItem,
  ApiOrganizationSimpleOut,
  ApiPOGeoLink,
  GenericHttpResult,
} from '@agunity/api-v4';

import { apiService } from 'api/apiService';
import { selectProfile } from 'features/login/redux';
import { selectOrganisation } from 'features/SPOReport/redux';
import { selectFeatureFlags } from 'features/Administration/redux';
import { ConsentState, FEATURE_FLAGS, PERMISSIONS } from 'appConstants';
import type { RootState } from 'app/store';

export const hot = '../features/OrganizationDetails/redux.ts';

type UploadFile = {
  file: File | null;
  itemType: number;
  itemId: string;
  name?: string;
  description?: string;
  url?: string;
};

export type UpdateOrgConsentState = {
  itemType: number;
  itemId: number;
  consentId: number;
  state: number;
};

export type CODSubset = {
  isActive?: boolean;
  floIds?: number[] | null;
  status?: ApiCODImpactStatus;
  years?: number[] | null;
};

const initialState = {
  consentItems: [],
  orgFloIds: [],
  codImpacts: [],
  codSubset: { isActive: false, floid: [], status: undefined, year: [] },
  geoLinksList: null,
  codForm: [],
  codQuery: { year: undefined, orgId: undefined },
};

export const counterSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    resetOrganization: () => initialState,
    setConsentItems: (state, action) => {
      state.consentItems = action.payload;
    },
    setOrgFloIds: (state, action) => {
      state.orgFloIds = action.payload;
    },
    setCODImpacts: (state, action) => {
      state.codImpacts = action.payload;
    },
    setCODSubset: (state, action) => {
      state.codSubset = action.payload;
    },
    setCODQuery: (state, action) => {
      state.codQuery = action.payload;
    },
    setCODForm: (state, action) => {
      state.codForm = action.payload;
    },
    geoLinksList: (state, action) => {
      state.geoLinksList = action.payload;
    },
  },
});

export const {
  resetOrganization,
  setConsentItems,
  setOrgFloIds,
  setCODImpacts,
  setCODSubset,
  geoLinksList,
  setCODForm,
  setCODQuery,
} = counterSlice.actions;

export const selectConsentItems = (state: RootState): ApiFTConsentItem[] => {
  return state.organization.consentItems;
};

export const selectOrgFloIds = (
  state: RootState
): ApiOrganizationSimpleOut[] => {
  return state.organization.orgFloIds;
};

export const selectCODImpacts = (
  state: RootState
): ApiCODImpactSummaryItem[] => {
  return state.organization.codImpacts;
};

export const selectCODForm = (state: RootState): ApiCODImpact[] => {
  return state.organization.codForm;
};

export const selectCODQuery = (
  state: RootState
): { orgId?: number; year?: number } => {
  return state.organization.codQuery;
};

export const selectCODSubset = (state: RootState): CODSubset => {
  return state.organization.codSubset;
};

export const getGeoLinksList = (state: RootState): any[] | null =>
  state.organization.geoLinksList;

export const updateOrgConsentState = ({
  itemType,
  itemId,
  consentId,
  state,
}: UpdateOrgConsentState) => {
  return async (): Promise<GenericHttpResult<void>> => {
    const response = await apiService.consentV10ItemPartialUpdate2(
      itemType,
      itemId,
      consentId,
      state
    );
    return response;
  };
};

export const getOrgConsentList = (force: boolean = true) => {
  return async (
    dispatch: Function,
    getState: Function
  ): Promise<GenericHttpResult<ApiFTConsentItem[]> | void> => {
    const consentItems = selectConsentItems(getState());
    if (!force && consentItems.length) return;
    const response = await apiService.fairtradeConsentOrgV10ListList();
    if (response.status === 200) dispatch(setConsentItems(response.data));
    return response;
  };
};

export const getOrgFLOIDs = (orgId: number, force: boolean = true) => {
  return async (
    dispatch: Function,
    getState: Function
  ): Promise<GenericHttpResult<ApiOrganizationSimpleOut[]> | void> => {
    if (!orgId) return;
    const orgFloIds = selectOrgFloIds(getState());
    if (!force && orgFloIds.length) return;
    const response = await apiService.fairtradeChildOrgsV11UserManagementDetail(
      orgId
    );
    if (response.status === 200) dispatch(setOrgFloIds(response.data));
    return response;
  };
};

export const uploadFile = ({ file, ...params }: UploadFile) => {
  return async (): Promise<GenericHttpResult<number> | void> => {
    if (!file) return;
    const data: any = new FormData();
    const fileBlob = new Blob([file.slice()], { type: file.type });
    data?.append('document', fileBlob, file.name ?? params?.name);
    data?.append('documentDetails', JSON.stringify(params));
    const response = await apiService.documentV12UploadCreate(data);
    return response;
  };
};

export const deleteFile = (documentKey: string) => {
  return async (): Promise<GenericHttpResult<void>> => {
    const response = await apiService.documentV10DeleteDelete(documentKey);
    return response;
  };
};

export const downloadDocument = (documentKey: string) => {
  return async (): Promise<GenericHttpResult<Blob>> => {
    const response = await apiService.documentV10DownloadDetail(documentKey);
    return response;
  };
};

export const getCODImpactList = (data: ApiCODImpactSummaryRequest) => {
  return async (
    dispatch: Function
  ): Promise<GenericHttpResult<ApiCODImpactSummaryItem[]>> => {
    const response = await apiService.fairtradeCodV10ListCreate(data);
    if (response.status === 200) dispatch(setCODImpacts(response.data));
    return response;
  };
};

export const getCODImpactYearList = () => {
  return async (): Promise<GenericHttpResult<number[]>> => {
    const response = await apiService.fairtradeCodV10ListYearsList();
    return response;
  };
};

export const getCODImpactByYear = ({
  orgId,
  year,
  force,
}: {
  orgId: number;
  year: number;
  force?: boolean;
}) => {
  return async (
    dispatch: Function,
    getState: Function
  ): Promise<GenericHttpResult<ApiCODImpact[]> | void> => {
    const codQuery = selectCODQuery(getState());
    const isSameQuery = codQuery.orgId === orgId && codQuery.year === year;

    if (!force && isSameQuery) return;
    const response = await apiService.fairtradeCodV10ItemDetail(orgId, year);
    await dispatch(setCODQuery({ orgId, year }));
    await dispatch(setCODForm(response.data || []));
    return response;
  };
};

export const postCODImpact = (orgId: number, data: ApiCODImpact) => {
  return async (): Promise<GenericHttpResult<void>> => {
    const response = await apiService.fairtradeCodV10ItemCreate(orgId, data);
    return response;
  };
};

export const initOrgConsent = (history) => {
  return async (dispatch: Function, getState: Function) => {
    const permissions = selectProfile(getState())?.permissions || [];
    const featureFlags = selectFeatureFlags(getState());
    const isPOProxy = permissions.includes(PERMISSIONS.PO_PROXY_READ);

    if (isPOProxy) {
      // Fetch the org FloId list and consent item list.
      const orgProfile = selectOrganisation(getState());
      await dispatch(getOrgFLOIDs(orgProfile?.id));

      const response = await dispatch(getOrgConsentList());

      // If at least one item has not yet been agreed/disagreed, redirect the user to the Org consent page.
      const hasNotYetGivenConsent = (response.data || []).some(
        ({ state }) => state === ConsentState.Blank
      );
      const hasOrgConsent = (featureFlags || []).includes(FEATURE_FLAGS[9]);
      if (hasNotYetGivenConsent && hasOrgConsent) {
        history.replace('/organizationDetails/organizationConsents');
      }
    }
  };
};

export const fetchGeoLinks = () => (async (dispatch: Function, getState: Function): Promise<GenericHttpResult<ApiPOGeoLink[]>> => {
  const response = await apiService.fairtradeV10GeoLinksList();
  if (response.status === 200) {
    dispatch(geoLinksList(response.data));
  } else {
    dispatch(geoLinksList([]));
  }
  return response;
});

export const updateGeoLink = ({ method, data }) => (async (dispatch: Function, getState: Function): Promise<GenericHttpResult<void>> => {
  const api = { POST: apiService.itemDataV10ItemCreate, PATCH: apiService.itemDataV11ItemPartialUpdate };
  const { id, geoLink } = data;
  const body = { itemType: 1, /* Item Type 1 = Organization */ key: 'GeoLink', /* Item Type */ itemId: id, /* Organization ID */ value: geoLink /* GeoLink */ };
  const response = await api[method](body);
  if (response.status === 200) { dispatch(fetchGeoLinks()); }
  return response;
});

export const uploadGeoLinks = ({ data }) => (async (dispatch: Function, getState: Function): Promise<GenericHttpResult<void>> => {
  const response = await apiService.fairtradeV10GeoLinksCreate(data);
  if (response.status === 200) { dispatch(fetchGeoLinks()); }
  return response;
});

export const downloadGeoLinksDocument = ({ key }) => (async (dispatch: Function, getState: Function): Promise<GenericHttpResult<Blob>> => {
  const response = await apiService.documentV10DownloadDetail(key);
  return response;
});

export default counterSlice.reducer;
