import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import styled from 'styled-components';

import AsyncButton from 'components/AsyncButton';
import { isDemo } from 'api/environments';

export const Container = styled.section`
  padding: 34px;
`;

export const ProxyContainer = styled.section`
  position: relative;
  padding: 24px 64px;
  height: ${() =>
    `calc(100vh - ${isDemo ? '105px' : '55px'})`};
`;

export const Title = styled.h4`
  font-size: 26px;
  font-weight: 500;
  line-height: 30.47px;
  margin-bottom: 40px;
`;

export const Title2 = styled.h5`
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 16px;
`;

export const Description = styled.p`
  font-size: 14px;
  line-height: 18px;
  max-width: 640px;
  color: #5c5c5c;
`;

export const Link = styled.a`
  font-size: 14px;
  line-height: 18px;
  color: #3284fb;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: #3284fb;
  }

  &.underline {
    text-decoration: underline;
  }
`;

export const Button = styled.button`
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  background: none;
  border: 0;
  color: #3284fb;
  margin-bottom: 0px;

  &.revoke {
    color: #af3827;
  }
`;

export const StyledGrid = styled(Grid)`
  justify-content: space-between;
  align-items: flex-end;
`;

export const Separator = styled.hr`
  border-color: 0.5px 0px 0px 0px solid #bec7d8;
`;

export const ThirdPartiesText = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  max-width: 640px;
  margin-top: 24px;
`;

export const MarkDownContainer = styled.section`
  max-height: 60vh;
  border-radius: 3px;
  border: 1px solid #e4e2e4;
  padding: 20px;
  overflow: auto;
  margin-bottom: 20px;

  ::-webkit-scrollbar {
    width: 11px;
  }

  ::-webkit-scrollbar-track {
    background: unset;
  }

  ::-webkit-scrollbar-thumb {
    padding: 2px;
    border-radius: 10px;
    border: 2px solid white;
    background: #e4e2e4;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #ccc;
  }
`;

export const ConsentNote = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #706d6d;
  margin: 0;
`;

export const StyledDialog = styled(Dialog)`
  [role='dialog'] {
    padding: 10px;
  }
`;

export const StyledDialogTitle = styled(DialogTitle)`
  font-size: 20px;
  font-weight: 700 !important;
`;

export const StyledDialogContent = styled(DialogContent)`
  padding-bottom: 0 !important;
  .checkbox {
    display: flex;
    align-items: center;
    padding-top: 20px;
  }
  [data-testid='consent-checkbox'] {
    margin-left: -13px;
  }
`;

export const StyledDialogActions = styled(DialogActions)`
  padding: 30px 24px !important;
  justify-content: space-between !important;

  &.end {
    justify-content: flex-end !important;
    padding-top: 0px !important;
  }
`;

export const StyledAsyncButton = styled(AsyncButton)`
  max-width: max-content;
  text-transform: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin: 0 !important;
  padding: 10px 32px !important;
  height: 44px;

  &.disagree {
    background-color: unset;
    border: 2px solid #2c3d52;
    color: #2c3d52;
  }
`;

export const Container2 = styled.section`
  max-width: 560px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Title3 = styled.h4`
  font-size: 20px;
  font-weight: 700;
`;

export const DeleteWrapper = styled.span`
  color: #af3827;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
  cursor: pointer;

  svg {
    width: 24px;
    margin-right: 5px;
  }
`;

export const UploadWrapper = styled.div`
  position: relative;
  color: #3284fb;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
  cursor: pointer;

  svg {
    width: 24px;
    margin-right: 5px;
  }

  input {
    cursor: pointer;
    position: absolute;
    opacity: 0;
    width: 90px;
    font-size: 0;
    padding: 10px 0;
  }
`;

export const DownloadLink = styled.span`
  color: #3284fb;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  text-decoration-line: underline;
`;

export const ClearText = styled.span`
  color: #af3827;
  font-size: 1em;
  font-weight: 700;
  cursor: pointer;
`;
