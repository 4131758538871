import type { ApiUILogEventRequest, GenericHttpResult } from '@agunity/api-v4';

import { apiService } from 'api/apiService';

export const loggingUIError = (props: ApiUILogEventRequest) => {
  return async (): Promise<GenericHttpResult<void>> => {
    const response = await apiService.loggingV10UiErrorCreate(props);
    return response;
  };
};
