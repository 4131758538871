import {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
} from 'react';

import Modal from 'components/shared/Modal';
import type { AsyncButtonProps } from 'components/AsyncButton';

type ModalContentProps = {
  title?: string;
  titleValues?: { [key: string]: string };
  redTitle?: boolean;
  content?: string;
  contentValues?: { [key: string]: string };
  isDoubleSpacing?: boolean;
  actions?: Omit<AsyncButtonProps, 'messageId'> | { messageId?: string }[];
  actionsAlign?: 'start' | 'end' | 'center' | 'space-between';
  customTitle?: ReactNode;
  customContent?: ReactNode;
  customActions?: ReactNode;
  hasCloseIcon?: boolean;
  closeIconOnclick?: () => void;
  modalMaxWidth?: string;
};
type ModalProps = {
  isOpen?: boolean;
  modalContent: ModalContentProps;
  openModal: (props: ModalContentProps) => void;
  closeModal: () => void;
};

const defaultModalContext: ModalProps = {
  isOpen: false,
  modalContent: {},
  openModal: () => {},
  closeModal: () => {},
};

const ModalContext = createContext<ModalProps>(defaultModalContext);

export const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const openModal = useCallback((props) => {
    setModalContent({ ...props });
    setIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
    setModalContent({});
  }, []);

  return (
    <ModalContext.Provider
      value={{ isOpen, openModal, closeModal, modalContent }}
    >
      {isOpen && <Modal />}
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = (): ModalProps => useContext(ModalContext);
