import styled from 'styled-components';

export const Page = styled.div`
  position: relative;
`;

export const Container = styled.div<any>`
  position: relative;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: ${({ theme }) => theme.BG};
  color:  ${({ theme }) => theme.Text};
  display: flex;
`;

export const Loader = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  height: 4em;
  margin-top: 10em;
`;

export const RightSection = styled.div`
  width:100%;
`;

export const DemoMessage = styled.div`
  font-family: Roboto;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  background-color: #000;
  padding: 0.5em;
  font-size: 20px;
  font-weight: 700;
  color: #F6F7F7;
  text-align: center;
  span {
    font-weight: 400;
  }
`;

export const ModalBody = styled.div``;

export const ModalBodyText = styled.p`
  margin: 0;
  color: #706D6D;
`;

export const ModalBodyTextList = styled.ul`
  margin: 5px 0 20px 0;
  padding-left: 30px;
  color: #706D6D;
`;

export const TextList = styled.li``;

export const ModalBodyForm = styled.div`
  .form-button {
    width: fit-content;
    margin: 0 20px 0 0;
    padding: 7px 25px;
    text-transform: initial;
    color: #2C3D52;
    border-color: #2C3D52 !important;
  }

  .save-button {
    margin: 0;
    color: #FFF !important;
    background-color: #2C3D52;

    &:hover {
      background-color: #2C3D52;
    }
  }
`;

export const FormInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5em 0 0 0;
  
  .Mui-disabled {
    background-color: #E4E2E4;
  }
`;

export const UploadHiddenInput = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1;
`;

export const UploadFileWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #000;
  line-height: 0;

  .MuiSvgIcon-root {
    margin-left: 5px;
    cursor: pointer;
  }
`;

export const UploadFileText = styled.span`
  max-width: 300px;
  word-break: break-word;
  line-height: normal;
`;

export const FormInputLabel = styled.label`
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 18px;
`;

export const FormActionWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin: 2.5em 0 0 0;
`;

export const Image = styled.img`
  width: 120px;
  height: 73px;

  &.mood {
    border: 3px solid;
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
    object-fit: none;
    cursor: pointer;

    &.mood-0 {
      background-color: #689E3C;
      border-color: #689E3C;
    }

    &.mood-1 {
      background-color: #D1EBBC;
      border-color: #D1EBBC;
    }

    &.mood-2 {
      background-color: #FFE6D1;
      border-color: #FFE6D1;
    }

    &.mood-3 {
      background-color: #E4803B;
      border-color: #E4803B;
    }

    &.disabled {
      cursor: default;
    }

    &:not(.disabled):hover {
      border-color: #3284FB;
    }

    &.active {
      border-color: #3284FB;
    }
  }
`;

export const Textarea = styled.textarea<any>`
  border-color: ${props => props.$error ? 'var(--error-color)' : props.$isLight ? '#E8E8E8' : '#E4E2E4' };
  border-radius: 4px;
  width: ${props => props.$width || '100%'};
  height: ${props => props.$height || 'none'};
  padding: 10px;
  resize: none;
`;
