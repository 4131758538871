import MediaQuery from 'react-responsive';
import { useContext } from 'react';

import App from 'features/page';
import { ModalProvider } from 'components/shared/Modal/Provider';
import { PrintContext } from 'components/Printing/Context';

const AppRouter = () => {
  return (
    <ModalProvider>
      <App />
    </ModalProvider>
  );
};

function Container() {
  const { isPrinting } = useContext(PrintContext);

  if (isPrinting) return <AppRouter />;

  if (process.env.NODE_ENV === 'test') return <AppRouter />;

  return (
    <>
      <MediaQuery minWidth={800}>
        <AppRouter />
      </MediaQuery>
      <MediaQuery maxWidth={799}>
        <div>
          This application is desktop based, please load in a desktop browser.
        </div>
      </MediaQuery>
    </>
  );
}

export default Container;
