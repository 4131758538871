import { IntlProvider } from 'react-intl';
import { useState, useMemo } from 'react';

import { selectLocaleData, selectSelectedLanguages } from 'features/Localisation/redux';
import { LanguageContext } from './Context';
import { useAppSelector } from 'app/hooks';

import defaults from './langs/default.json';

const getFirstBrowserLanguage = () => {
  var nav = window.navigator,
    browserLanguagePropertyKeys = [
      'language',
      'browserLanguage',
      'systemLanguage',
      'userLanguage'
    ],
    i,
    language;

  // support for HTML 5.1 "navigator.languages"
  if (Array.isArray(nav.languages)) {
    for (i = 0; i < nav.languages.length; i++) {
      language = nav.languages[i];
      if (language && language.length) {
        return language;
      }
    }
  }

  // support for other well known properties in browsers
  for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
    language = nav[browserLanguagePropertyKeys[i]];
    if (language && language.length) {
      return language;
    }
  }

  return null;
};

export default function LanguageProvider({ children }): JSX.Element {
  const localeData = useAppSelector(selectLocaleData);
  const selectedLanguages = useAppSelector(selectSelectedLanguages);

  function getDef(l: string) {
    let actual: string = 'en';
    if (selectedLanguages) {
      actual = selectedLanguages.locale;
    } else {
      Object.keys(localeData).forEach((key) => {
        if (l.includes(key)) return key;
      });
    }
    return actual;
  }

  const def = getFirstBrowserLanguage();
  const defaultLocale = window?.localStorage.getItem('locale') || getDef(def);

  const [locale, setLocale] = useState<string>(defaultLocale);
  const [locs, setLocales] = useState<any>();

  const updateLocale = (l: string) => {
    window?.localStorage.setItem('locale', l);
    setLocale(l);
  };

  const fLocs = { ...(localeData[locale] || {}) }; // Select localeData base on current locale
  const msgs =
    fLocs !== undefined && Object.keys(fLocs).length > 0 ? fLocs : defaults;

  const messages: any = useMemo(() => {
    const list = [];
    for (let [key] of Object.entries(defaults)) {
      list[key] = msgs?.[key] || defaults[key];
    }
    return list;
  }, [msgs]);

  const handleError = (error: {message: string}) => {
    console.log('language file is not complete, please update', error.message);
  };

  return (
    <LanguageContext.Provider
      value={{
        setLocale: updateLocale,
        locale,
        locales: locs,
        messages,
        setLangLocales: setLocales
      }}
    >
      <IntlProvider
        messages={messages}
        locale={locale}
        defaultLocale='en'
        onError={(error)=>{
          handleError(error);}}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
}
