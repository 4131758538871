import { lazy, useEffect } from 'react';

import { fetchReports, selectReports } from './redux';
import { PERMISSIONS } from 'appConstants';
import { selectProfile } from 'features/login/redux';
import { useAppSelector } from 'app/hooks';
import { useLocaleUpdate } from 'components/Language/hooks';
import { useToast } from './hooks';
import ErrorsProvider from 'components/Errors/Provider';
import Route from 'components/ErrorBoundary';

const ListReports = lazy(() => import('./screens/ListReports'));
const SelectCategory = lazy(() => import('./screens/SelectCategory'));
const ProjectDetails = lazy(() => import('./screens/ProjectDetails'));
const Beneficiaries = lazy(() => import('./screens/Beneficiaries'));
const Review = lazy(() => import('./screens/Review'));
const Budget = lazy(() => import('./screens/Budget'));
const DraftPage = lazy(() => import('./screens/DraftPage'));
const FieldStaffView = lazy(() => import('./screens/FieldStaffView'));
const ApprovedPlans = lazy(() => import('./screens/ApprovedPlans'));
const SummaryPlan = lazy(() => import('./screens/SummaryPlan'));
const BulkUploadMain = lazy(() => import('./screens/Bulk/BulkUploadMain'));

export const applyPerm = (profile, perm, component, forceShow = false) => {
  const hasPermission = (profile?.permissions || []).some((id) => {
    if (Array.isArray(perm)) {
      return perm.includes(id);
    } else {
      return id === perm;
    }
  });

  if (forceShow || hasPermission) {
    return component;
  } else if (JSON.stringify(profile) === '{}') {
    return (
      <div style={{ width: '100%', padding: '10em 4em', textAlign: 'center' }}>
        Loading...
      </div>
    );
  } else {
    return (
      <div style={{ width: '100%', padding: '4em', textAlign: 'center' }}>
        Not authorised to view.
      </div>
    );
  }
};

function SPOReport() {
  // const { errors } = useLocaleUpdate(fetchReports); //to avoid calling api/Plan/V1.1/List and improve performance
  const { errors } = useLocaleUpdate(selectReports); //to avoid calling api/Plan/V1.1/List and improve performance
  const { toast } = useToast();
  const profile = useAppSelector(selectProfile);

  useEffect(() => {
    if (!errors) return;
    toast('errors', 'error', { errors: errors.join(',') });
  }, [errors, toast]);

  return (
    <div style={{ paddingBottom: 60, position: 'relative', backgroundColor: '#fff' }}>
      <ErrorsProvider>
        <Route exact path='/'>
          {applyPerm(profile, PERMISSIONS.VIEW, <ListReports />)}
        </Route>
        <Route exact path='/report/draft/:id'>
          {applyPerm(profile, PERMISSIONS.VIEW, <DraftPage />)}
        </Route>
        <Route exact path='/report/approved/:id'>
          {applyPerm(profile, PERMISSIONS.VIEW, <ApprovedPlans />)}
        </Route>
        <Route exact path='/report/approved/:id/:tab'>
          {applyPerm(profile, PERMISSIONS.VIEW, <ApprovedPlans />)}
        </Route>
        <Route exact path='/report/past/:id'>
          {applyPerm(profile, PERMISSIONS.VIEW, <ApprovedPlans />)}
        </Route>
        <Route exact path='/report/past/:id/:tab'>
          {applyPerm(profile, PERMISSIONS.VIEW, <ApprovedPlans />)}
        </Route>
        <Route exact path='/reportsList/bulk'>
          {applyPerm(profile, PERMISSIONS.VIEW, <BulkUploadMain />)}
        </Route>
        <Route exact path='/reports/:id/selectCategory'>
          {applyPerm(profile, [PERMISSIONS.CREATE, PERMISSIONS.PROXY_PLANNER], <SelectCategory />, true)}
        </Route>
        <Route exact path='/reports/:id/details'>
          {applyPerm(profile, [PERMISSIONS.CREATE, PERMISSIONS.PROXY_PLANNER], <ProjectDetails />, true)}
        </Route>
        <Route exact path='/reports/:id/details/:startYear'>
          {applyPerm(profile, [PERMISSIONS.CREATE, PERMISSIONS.PROXY_PLANNER], <ProjectDetails />, true)}
        </Route>
        <Route exact path='/reports/:id/budget'>
          {applyPerm(profile, [PERMISSIONS.CREATE, PERMISSIONS.PROXY_PLANNER], <Budget />, true)}
        </Route>
        <Route exact path='/reports/:id/beneficiaries'>
          {applyPerm(profile, [PERMISSIONS.CREATE, PERMISSIONS.PROXY_PLANNER], <Beneficiaries />, true)}
        </Route>
        <Route exact path='/reports/:id/reviews'>
          {applyPerm(profile, [PERMISSIONS.CREATE, PERMISSIONS.PROXY_PLANNER], <Review />, true)}
        </Route>
        <Route exact path='/reportsList/:status/:id'>
          {applyPerm(profile, PERMISSIONS.VIEW, <SummaryPlan />)}
        </Route>
        <Route exact path='/reportsList/:status'>
          {applyPerm(profile, PERMISSIONS.VIEW, <ListReports />)}
        </Route>
        <Route exact path='/fieldStaff'>
          {applyPerm(profile, PERMISSIONS.FIELD_STAFF_VIEW, <FieldStaffView />)}
        </Route>
      </ErrorsProvider>
    </div>
  );
}

export default SPOReport;
