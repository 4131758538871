import styled from 'styled-components';

import AsyncButton from 'components/AsyncButton';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 10%;
  gap: 10px;
`;

export const Title = styled.h1`
  margin-bottom: 20px;
`;

export const Description = styled.p`
  padding: 0;
  margin: 0;
`;

export const Link = styled.a`
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
`;

export const ActionContainer = styled.section`
  display: flex;
  gap: 20px;
  margin-top: 50px;
`;

export const StyledAsyncButton = styled(AsyncButton)`
  max-width: max-content;
  text-transform: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin: 0 !important;
  padding: 10px 32px !important;
  height: 44px;
`;
