import MuiDropdown from '../MuiDropdown/MuiDropdown';
import { useState, useContext, useEffect } from 'react';
import { LanguageContext } from 'components/Language/Context';
import {
  currencyConversions,
  getCurrencyConversions,
  selectedCurrency as setSelectedCurrency,
  selectSelectedCurrency
} from 'features/SPOReport/redux';
import { useAppDispatch, useAppSelector } from 'app/hooks';

export enum CurrencyTypes {
  Original = 'Original currency',
  USD = 'USD',
  EUR = 'EUR'
}

const CurrencyDropdown = ({
  setSelectedCurrencyx
} : {
  setSelectedCurrencyx: Function;
}) => {
  const { messages } = useContext(LanguageContext);
  const selectedCurrency = useAppSelector(selectSelectedCurrency);
  const [selectedCur, setSelectedCur] = useState<string>((selectedCurrency === CurrencyTypes['Original']) ? messages.originalCur : selectedCurrency);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setSelectedCurrencyx(selectedCur);
  }, [selectedCur, setSelectedCurrencyx]);

  const options = ['Original', 'EUR', 'USD'].map((val, index) => ({
    id: index,
    value: val,
    label: ((val === 'Original') ? messages.originalCur : undefined)
  }));

  const fetchData = async () => {
    dispatch(currencyConversions(undefined));
    const response = await dispatch(getCurrencyConversions());
    if (response.status === 200) { dispatch(currencyConversions(response.data)); }
  };

  const handleChange = (event) => {
    setSelectedCur((event?.target.value as string) === 'Original' ? messages.originalCur : (event?.target.value as string));
    dispatch(setSelectedCurrency(CurrencyTypes[event?.target.value as string]));
    if (CurrencyTypes[event?.target.value as string] !== CurrencyTypes.Original) { fetchData(); }
  };

  return (
    <MuiDropdown
      style={{ minWidth: '160px', marginRight: '13px', textAlign: 'left' }}
      labelColor='black'
      labelWeight={700}
      value={selectedCur}
      onChange={handleChange}
      options={options}
    />
  );
};

export default CurrencyDropdown;
