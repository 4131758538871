import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import styled from 'styled-components';

import AsyncButton from 'components/AsyncButton';

export const StyledDialog = styled<any>(Dialog)`
  .MuiPaper-root {
    position: relative;
    max-width: ${(p) => (p?.modalmaxwidth ? p.modalmaxwidth : '640px')};
    padding: 40px 48px;
  }

  button.close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 24px;
    color: #000;
  }
`;

export const StyledDialogTitle = styled(DialogTitle)`
  font-size: 20px !important;
  font-weight: 700 !important;
  padding: 0 !important;

  &.red {
    color: #af3827;
  }
`;

export const StyledDialogContent = styled(DialogContent)<any>`
  padding: 0px !important;
  white-space: pre-line;
  color: #706d6d;
  font-size: 14px;
  margin-top: 24px;
  line-height: 18px;

  &.withActions {
    margin-bottom: 48px;
  }

  &.double {
    margin-bottom: 36px;
    line-height: 36px;
  }
`;

export const StyledDialogActions = styled(DialogActions)`
  color: red;
  padding: 0 !important;
  gap: 10px;

  &.start {
    justify-content: start;
  }

  &.center {
    justify-content: center;
  }

  &.end {
    justify-content: end;
  }

  &.space-between {
    justify-content: space-between;
  }
`;

export const StyledAsyncButton = styled(AsyncButton)`
  max-width: max-content;
  text-transform: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin: 0 !important;
  padding: 10px 32px !important;
  height: 44px;
`;
