import React from 'react';
import { IconButton } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { CloseOutlined } from '@material-ui/icons';

import { selectStatus } from 'api';
import { useModal } from './Provider';
import { useAppSelector } from 'app/hooks';
import * as S from './styled';

const Modal = React.memo(() => {
  const { isOpen, modalContent, closeModal } = useModal();
  const status = useAppSelector(selectStatus);
  const {
    title = '',
    titleValues = {},
    customTitle = '',
    redTitle = false,
    content = '',
    contentValues = {},
    customContent = '',
    isDoubleSpacing = false,
    actions = [],
    customActions = '',
    actionsAlign = 'end',
    hasCloseIcon = false,
    closeIconOnclick,
    modalMaxWidth,
  } = modalContent || {};

  const hasActions = !!(actions.length || customActions);
  const actionButtons = actions.map(
    ({ name, messageId, onClick, ...otherProps }, index) => (
      <S.StyledAsyncButton
        key={`${index}-${status}`}
        onClick={() => (onClick ? onClick(messageId ?? name) : closeModal())}
        messageId={messageId ?? 'Confirm'}
        testId={messageId || 'modal-button'}
        isBlue={!otherProps.isCancel && !otherProps.isBordered && !otherProps.isSave}
        {...otherProps}
      />
    )
  );

  const handleClose = () => {
    closeIconOnclick && closeIconOnclick();
    closeModal();
  };

  return (
    <S.StyledDialog
      fullWidth
      open={isOpen ?? false}
      modalmaxwidth={modalMaxWidth}
      data-testid='modal-root'
    >
      {hasCloseIcon ? (
        <IconButton
          className='close'
          data-testid='modal-close-icon'
          onClick={handleClose}
        >
          <CloseOutlined />
        </IconButton>
      ) : null}

      <S.StyledDialogTitle
        className={redTitle ? 'red' : ''}
        data-testid='modal-title'
      >
        {customTitle || (
          <FormattedMessage id={title} values={{ ...titleValues }} />
        )}
      </S.StyledDialogTitle>
      <S.StyledDialogContent
        className={isDoubleSpacing ? 'double' : hasActions ? 'withActions' : ''}
        data-testid='modal-content'
      >
        {customContent || (
          <FormattedMessage id={content} values={{ ...contentValues }} />
        )}
      </S.StyledDialogContent>
      {hasActions ? (
        <S.StyledDialogActions className={actionsAlign} data-testid='modal-action-wrapper'>
          {customActions || actionButtons}
        </S.StyledDialogActions>
      ) : null}
    </S.StyledDialog>
  );
});

export default Modal;
