import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Route, useHistory } from 'react-router';
import { ErrorBoundary } from 'react-error-boundary';

import { appEnv, isDevelop } from 'api/environments';
import { API_LOG_LEVEL } from 'appConstants';
import { capitalizeFirstLetter } from 'utils';
import { loggingUIError } from 'features/redux';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectCredentials, selectVersion } from 'features/login/redux';
import { version } from '../../../package.json';
import * as S from './styled';

let debounce: NodeJS.Timeout;
export const ErrorFallback = ({
  error,
  resetErrorBoundary,
  isParent = false,
}) => {
  const { replace } = useHistory();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const creds = useAppSelector(selectCredentials);
  const APIVersion = useAppSelector(selectVersion);

  const helpLink = 'https://doc.agunity.com/fairinsight-help-desks';

  const handleGoToHome = () => {
    if (isParent && location) location.href = '/';
    else replace('/');
  };

  const logUIError = useCallback(async () => {
    clearTimeout(debounce);
    debounce = setTimeout(async () => {
      try {
        if (isDevelop) return;
        const payload = {
          level: API_LOG_LEVEL.Critical as number,
          userToken: creds?.userToken,
          target: 'Insight',
          url: location?.href,
          stackTrace: error?.stack,
          apiVersion: `${APIVersion?.environmentName} ${APIVersion?.assemblyVersion}`,
          uiVersion: capitalizeFirstLetter(`${appEnv} ${version}`),
        };

        await dispatch(loggingUIError(payload));
      } catch (err) {
        console.error('Error logging UI error:', err);
      }
    }, 500);
  }, [
    APIVersion.assemblyVersion,
    APIVersion.environmentName,
    creds?.userToken,
    dispatch,
    error.stack,
  ]);

  useEffect(() => {
    logUIError();
    return () => clearTimeout(debounce);
  }, [logUIError]);

  return (
    <S.Container data-testid='uiErrorContainer'>
      {/* Title */}
      <S.Title data-testid='uiErrorTitle'>
        {formatMessage({ id: 'uiErrorTitle' })}
      </S.Title>

      {/* Message */}
      <S.Description data-testid='uiErrorMessage1'>
        {formatMessage({ id: 'uiErrorMessage1' })}
      </S.Description>

      <S.Description data-testid='uiErrorMessage2'>
        {formatMessage({ id: 'uiErrorMessage2' })}{' '}
        <S.Link data-testid='uiErrorMessage3' href={helpLink} target='_blank'>
          {formatMessage({ id: 'uiErrorMessage3' })}
        </S.Link>
      </S.Description>

      {/* Action Buttons */}
      <S.ActionContainer>
        <S.StyledAsyncButton
          isBlue
          noStatus
          testId='home'
          messageId='home'
          onClick={handleGoToHome}
        />
        <S.StyledAsyncButton
          isBordered
          testId='refresh'
          messageId='refresh'
          onClick={resetErrorBoundary}
        />
      </S.ActionContainer>
    </S.Container>
  );
};

const RouteWithErrorBoundary = (props) => (
  <Route {...props}>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {props.children}
    </ErrorBoundary>
  </Route>
);

export default RouteWithErrorBoundary;
