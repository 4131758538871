import styled from 'styled-components';
import '@fontsource/roboto';

import { isDemo } from 'api/environments';

export const Container = styled.div<{ $isToggled: boolean }>`
  width: 100%;
  height: 3em;
  background-color: #fff;
  position: fixed;
  padding-left: ${(props) => (props.$isToggled ? '-1em' : '16em')};
  padding-top: 0.25em;
  top: ${() => (isDemo ? '2.75em' : '0')};
  z-index: 98;
`;

export const Grow = styled.div`
  flex-grow: 1;
  background-color: #fff;
  border-bottom: 1px solid #e8e8e8;
`;

export const Grow2 = styled.div`
  flex-grow: 1;
  background-color: #fff;
`;

export const Title = styled.h3`
  color: #424b5a;
  font-weight: normal;
  font-size: 1.3em;
`;

export const Search = styled.div`
  position: relative;
  border-radius: 3em;
  background-color: light;
  margin-right: 5em;
  width: 10em;
`;

export const SectionDesktop = styled.div`
  display: flex;
  align-items: center;
`;

export const SVG = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 4px;
`;

export const SVG_Icon = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 4px;
`;

export const MenuItem = styled.label`
  padding-top: 0.3em;
  width: auto;
  height: auto;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;

  &.action {
    cursor: pointer;
  }
`;

export const Divider = styled.div`
  width: 80%fit-content;
  height: 1px;
  border: 0.5px solid #bec7d8;
`;

export const Dot = styled.div`
  width: 4px;
  height: 4px;
  background: #000000;
  margin-left: 2em;
`;
