import { createSlice } from '@reduxjs/toolkit';
import type { ApiLocaleDef, ApiLocalizationData, GenericHttpResult } from '@agunity/api-v4';

import { RootState } from 'app/store';
import { apiService } from 'api/apiService';
import { APP_NAME, CLIENT_NAME } from 'appConstants';

export const hot = '../features/Localisation/redux.ts';

import defaults from 'components/Language/langs/default.json';

export interface LanguageProps {
  locale: string;
  name: string;
  id: number;
  updatedAsOf: string;
  updatedBy: number
}

export interface LocaleState {
  locales: any;
  languages: LanguageProps[];
  selectedLanguage: LanguageProps
}

const initialState: LocaleState = {
  locales: { en: defaults },
  languages: [],
  selectedLanguage: { locale: 'en', name: 'English', id: 1, updatedAsOf: '', updatedBy: 1 },
};

export const localesSlice = createSlice({
  name: 'locales',
  initialState,
  reducers: {
    resetLocalesState: () => initialState,
    locales: (state, action) => {
      state.locales = action.payload;
    },
    languages: (state, action) => {
      state.languages = action.payload;
    },
    selectedLanguages: (state, action) => {
      state.selectedLanguage = action.payload;
    }
  }
});

export const { locales, languages, selectedLanguages, resetLocalesState } = localesSlice.actions;

export const selectLocaleData = (state: RootState) => state.locales.locales;

export const selectLanguages = (state: RootState) => state.locales.languages;
export const selectSelectedLanguages = (state: RootState) => state.locales.selectedLanguage;

export const fetchLocaleData =
  () =>
    async (dispatch: Function, getState: Function): Promise<(GenericHttpResult<string>)[] | void> => {
      const langs = selectLanguages(getState());
      if (!langs || langs.length === 0) return;
      const responses: Promise<GenericHttpResult<string>>[] = langs.map((loc) => {
        return apiService.languageV10LocalizationUnauthDetail(APP_NAME, loc.locale).catch(err => err);
      });
      const results = await Promise.all(responses);
      const locs: any = {};
      langs.forEach((loc, index) => {
        locs[loc.locale] = results[index].data;
      });

      dispatch(locales(locs));
      return results;
    };

export const fetchSelectedLocaleData =
  (langData?: { locale: string; }) =>
    async (dispatch: Function, getState: Function): Promise<GenericHttpResult<ApiLocalizationData[]> | void> => {
      try {
        const lang = langData || selectSelectedLanguages(getState());
        if (!Boolean(lang.locale)) return;

        const response = await apiService.languageV10LocalizationUnauthDetail(APP_NAME, lang.locale);
        if (response.data) {
          const currentLocales = selectLocaleData(getState());
          dispatch(locales({ ...currentLocales, [lang.locale]: response.data }));
        }
        return response;
      } catch (error) {
        languages('en');
        console.error(error);
      }
    };

export const saveLocaleData =
  (locale: string, data: any) =>
    async ( dispatch: Function ): Promise<GenericHttpResult<ApiLocalizationData[]>> => {
      const response = await apiService.languageV10LocalizationCreate(APP_NAME, locale, data);
      dispatch(fetchSelectedLocaleData({ locale }));
      
      return response;
    };

export const fetchLanguages =
  () =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<ApiLocaleDef[]> | void> => {
      try {
        const lang = selectLanguages(getState());
        if (lang?.length) return { status: 200, data: lang, headers: {} };
        const response = await apiService.languageV20LocalesDetail(CLIENT_NAME);
        dispatch(languages(response.data));
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

export default localesSlice.reducer;
