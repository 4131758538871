import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

import { selectStatus, selectInProgress } from 'api';
import { useAppSelector } from 'app/hooks';
import * as S from './styled';

export type AsyncButtonProps = {
  testId?: string;
  messageId: string;
  onClick?: Function;
  width?: string;
  noPadding?: boolean;
  to?: string;
  noStatus?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  isPrimary?: boolean;
  isSave?: boolean;
  isBlue?: boolean;
  disabled?: boolean;
  isCancel?: boolean;
  isBordered?: boolean;
  icon?: any;
  boldText?: boolean;
  marginRight?: string;
  padding?: string;
  style?: any;
  underlineCancel?: boolean;
  fontSize?: string;
  [key: string]: any;
};

function AsyncButton({
  testId,
  messageId,
  onClick,
  width,
  noPadding,
  noStatus,
  to,
  type = 'button',
  isPrimary,
  isSave,
  isBlue,
  disabled,
  isCancel,
  boldText,
  marginRight,
  isBordered,
  icon,
  style,
  padding,
  underlineCancel,
  fontSize,
  ...otherProps
}: AsyncButtonProps) {
  const status = useAppSelector(selectStatus);
  const count = useAppSelector(selectInProgress);
  const history = useHistory();
  const waitingForApi = !!(!noStatus && status !== 'idle' && count > 0);

  const handleClick = () => {
    if (to) history.push(to);
    else if (onClick) onClick();
  };

  return (
    <>
      {/*TODO: Need to remove this extra hidden test button.*/}
      {testId && (
        <button
          style={{ display: 'none' }}
          data-testid={testId}
          onClick={handleClick}
        >
          {messageId}
        </button>
      )}
      {isCancel ? (
        <S.CancelButton
          data-testid={`${testId}-cancel`}
          $noStatus={noStatus}
          $isPrimary={isPrimary}
          $noPadding={noPadding}
          $fontSize={fontSize}
          $width={width}
          $underline={underlineCancel}
          disabled={disabled || waitingForApi}
          onClick={handleClick}
          type={type}
          marginRight={marginRight}
          style={style}
          {...otherProps}
        >
          <S.BoldText boldText={boldText}>
            {waitingForApi ? (
              <FormattedMessage id='waiting' />
            ) : (
              <FormattedMessage id={messageId} />
            )}
          </S.BoldText>
        </S.CancelButton>
      ) : isBlue ? (
        <S.BlueButton
          data-testid={`${testId}-blue`}
          $noStatus={noStatus}
          $isPrimary={isPrimary}
          $noPadding={noPadding}
          $width={width}
          $padding={padding}
          $fontSize={fontSize}
          disabled={disabled || waitingForApi}
          onClick={handleClick}
          type={type}
          {...otherProps}
        >
          {!!icon && icon}
          <S.BoldText boldText={boldText}>
            {waitingForApi ? (
              <FormattedMessage id='waiting' />
            ) : (
              <FormattedMessage id={messageId} />
            )}
          </S.BoldText>
        </S.BlueButton>
      ) : isSave ? (
        <S.ButtonSave
          data-testid={`${testId}-save`}
          $noStatus={noStatus}
          $isPrimary={isPrimary}
          $noPadding={noPadding}
          $width={width}
          disabled={disabled || waitingForApi}
          onClick={handleClick}
          type={type}
          {...otherProps}
        >
          {!!icon && icon}
          {waitingForApi ? (
            <FormattedMessage id='waiting' />
          ) : (
            <FormattedMessage id={messageId} />
          )}
        </S.ButtonSave>
      ) : isBordered ? (
        <S.BorderedButton
          data-testid={`${testId}-bordered`}
          $noStatus={noStatus}
          $isPrimary={isPrimary}
          $noPadding={noPadding}
          $width={width}
          disabled={disabled || waitingForApi}
          onClick={handleClick}
          type={type}
          {...otherProps}
        >
          {!!icon && icon}
          {waitingForApi ? (
            <FormattedMessage id='waiting' />
          ) : (
            <FormattedMessage id={messageId} />
          )}
        </S.BorderedButton>
      ) : (
        <S.Button
          data-testid={`${testId}-default`}
          $noStatus={noStatus}
          $isPrimary={isPrimary}
          $noPadding={noPadding}
          $width={width}
          disabled={disabled || waitingForApi}
          onClick={handleClick}
          type={type}
          {...otherProps}
        >
          {waitingForApi ? (
            <FormattedMessage id='waiting' />
          ) : (
            <FormattedMessage id={messageId} />
          )}
        </S.Button>
      )}
    </>
  );
}

export default AsyncButton;
