export const apiUrl = process.env.REACT_APP_API || 'https://dev.api4.agunity.com';
export const appUrl = process.env.REACT_APP_URL;
export const isDemo = process.env.REACT_APP_DEMO === '1';

export const appEnv = process.env.REACT_APP_ENV;
export const isDevelop = appEnv === 'development';
export const isProd = appEnv === 'production';

export const SSOTenant = process.env.REACT_APP_SSO_TENANT || 'f5275b2e-0094-4c22-b696-1003da5bfddb';
export const SSOClientID = process.env.REACT_APP_SSO_CLIENT_ID || (isProd ? 'a75a26cf-4cbb-4627-933b-b16be83335c7' : 'ad1818b2-7db3-49a8-af71-7c09c9bfe0c3') ;
export const SSOScope = process.env.REACT_APP_SSO_SCOPE || (isProd ? 'api://3efa4de9-eab0-45e2-b13e-3a02287cfbe6/AgUnity_Access' : 'api://eecc7a2d-20bc-4ccc-8dff-7ef453da4093/AgUnity_Access');
