import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';
import { useState, useEffect, useContext, useRef } from 'react';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';

import {
  TextContainer,
  Label,
  RightLabel,
  Input,
  Textarea,
  SubLabel,
  Icon,
  PasswordContainer,
  DefaultInputContainer,
} from './styled';

import { LanguageContext } from 'components/Language/Context';
import { truncateFileNames } from 'utils';
import RemoveRedEyeOutlined from '@material-ui/icons/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { IconButton } from '@mui/material';

type Props = {
  Id?: string;
  testId?: string;
  value?: string | number | Date;
  defaultValue?: any;
  label?: string | ReactElement;
  labelId?: string;
  description?: string | ReactElement;
  placeholder?: any;
  placeholderId?: string;
  onChange: Function;
  onBlur?: Function;
  type?: string;
  maxLen?: number;
  showMaxLenPlaceholder?: boolean;
  rightLabel?: any;
  error?: boolean;
  invalid?: boolean;
  isLight?: boolean;
  width?: string;
  rows?: number;
  disabled?: boolean;
  locked?: boolean;
  icon?: any;
  accept?: string;
  fixedHeight?: string;
  fileName?: string | any;
  maxDate?: any;
  height?: string;
  alignRight?: boolean;
  containerStyle?: any;
  showErrorText?: boolean;
  inputFieldStyles?: Object;
  onRemoveClick?: Function;
  fref?: any;
  [key: string]: any;
  hasFile?: boolean;
};

function TextInput({
  testId,
  value,
  defaultValue,
  label,
  labelId,
  description,
  onChange,
  placeholder,
  placeholderId,
  type = 'text',
  maxLen = 50,
  showMaxLenPlaceholder = false,
  rightLabel,
  error,
  invalid,
  isLight,
  onBlur,
  width,
  rows,
  disabled,
  locked,
  icon,
  accept,
  fixedHeight,
  fileName,
  maxDate,
  Id,
  height,
  alignRight,
  containerStyle,
  showErrorText = true,
  inputFieldStyles,
  onRemoveClick,
  fref,
  hasFile,
  endIcon,
  ...otherProps
}: Props) {
  const [id, setId] = useState<string>();
  const [validationError, setvalidationError] = useState<boolean>(false);
  const [isShow, setIsShow] = useState(false);
  const { messages } = useContext(LanguageContext);
  const fileRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  let placeHolder = placeholder || (placeholderId && messages[placeholderId]);
  if (showMaxLenPlaceholder && maxLen) {
    placeHolder = `${
      placeHolder ? placeHolder + '. ' : ''
    }${messages.chars.replace('{max}', maxLen)}`;
  }

  useEffect(() => {
    if (process.env.NODE_ENV === 'test') return;
    import('uuid').then((uuid) => {
      setId(uuid.v1());
    });
  }, []);

  const preChangeValidation = (value: any) => {
    if (type === 'date' && maxDate) {
      if (isAfter(new Date(value), new Date(maxDate))) {
        setvalidationError(true);
        return;
      } else {
        setvalidationError(false);
      }
    }
    if (type === 'date' && otherProps.min) {
      if (isBefore(new Date(value), new Date(otherProps.min))) {
        setvalidationError(true);
        return;
      } else {
        setvalidationError(false);
      }
    }
    onChange(value);
  };

  let input: any = null;
  if (type === 'textarea') {
    input = (
      <Textarea
        data-testid={testId}
        className='form-control'
        $isLight={isLight}
        $error={error}
        $width={width}
        $height={height}
        rows={rows}
        value={value}
        defaultValue={defaultValue}
        onChange={(e) => onChange(e.target.value)}
        type={type}
        placeholder={placeHolder}
        maxLength={maxLen}
        onBlur={onBlur}
        disabled={disabled}
        locked={locked}
        id={Id}
        style={inputFieldStyles}
        {...otherProps}
      />
    );
  } else if (type === 'file') {
    const uploadedFile = fileName?.props?.id !== 'choosefile';
    let name;
    if (defaultValue) {
      name = defaultValue.name;
    }

    input = (
      <div
        className='custom-file'
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if ((name || uploadedFile || hasFile) && onRemoveClick) {
            if ((fref || fileRef).current.files) {
              (fref || fileRef).current.files = new DataTransfer().files;
            }
            return onRemoveClick(e);
          } else if (!(name || uploadedFile)) {
            (fref || fileRef)?.current?.click();
          }
          return null;
        }}
      >
        <Input
          ref={fref || fileRef}
          type='file'
          disabled={disabled}
          accept={accept}
          onClick={e => {
            e.stopPropagation();
          }}
          onChange={(e) => onChange(e)}
          id={Id || id}
          style={inputFieldStyles}
          {...otherProps}
        />

        <label
          className={
            !!name || (fileName && uploadedFile)
              ? 'custom-fileuploaded-label custom-file-label'
              : 'custom-file-label'
          }
          htmlFor={Id || id}
        >
          {!!name
            ? truncateFileNames(name)
            : typeof fileName === 'string'
              ? truncateFileNames(fileName)
              : fileName}
        </label>
      </div>
    );
  } else if (type === 'password') {
    input = (
      <PasswordContainer>
        <Input
          data-testid={testId}
          $hasIcon={!!icon}
          $isLight={isLight}
          $error={error || validationError}
          $invalid={invalid}
          $width={width}
          $height={height}
          value={value}
          defaultValue={defaultValue}
          onChange={(e) => {
            const value = e.target.value.replace(/\s+/g, '');
            preChangeValidation(value);
          }}
          type={isShow ? 'text' : 'password'}
          placeholder={placeHolder}
          maxLength={maxLen}
          onBlur={onBlur}
          disabled={disabled}
          max={maxDate}
          id={Id}
          $alignRight={alignRight}
          $hasRightLabel={!!rightLabel}
          style={inputFieldStyles}
          {...otherProps}
        />
        <IconButton onClick={() => setIsShow((prev) => !prev)}>
          {isShow ? <VisibilityOffOutlinedIcon /> : <RemoveRedEyeOutlined />}
        </IconButton>
      </PasswordContainer>
    );
  } else {
    input = (
      <DefaultInputContainer>
        <Input
          data-testid={testId}
          $hasIcon={!!icon}
          $isLight={isLight}
          $error={error || validationError}
          $invalid={invalid}
          $width={width}
          $height={height}
          value={value}
          defaultValue={defaultValue}
          onChange={(e) => preChangeValidation(e.target.value)}
          type={type}
          placeholder={placeHolder}
          maxLength={maxLen}
          onBlur={onBlur}
          disabled={disabled}
          locked={locked}
          max={maxDate}
          id={Id}
          $alignRight={alignRight}
          $hasRightLabel={!!rightLabel}
          style={inputFieldStyles}
          {...otherProps}
        />
        {endIcon}
      </DefaultInputContainer>
    );
  }

  return (
    <TextContainer
      $width={width}
      $fixedHeight={fixedHeight}
      style={containerStyle}
    >
      {label && (
        <Label
          $disabled={disabled}
          $isLight={isLight}
          htmlFor={Id || otherProps.id}
        >
          <span>{label}</span>
        </Label>
      )}
      {labelId && (
        <Label $isLight={isLight} htmlFor={Id || otherProps.id}>
          <span>
            <FormattedMessage id={labelId} />
          </span>
        </Label>
      )}
      {description && (
        <SubLabel $isLight={isLight}>
          <span>{description}</span>
        </SubLabel>
      )}
      {rightLabel && (
        <RightLabel>
          <span>{rightLabel}</span>
        </RightLabel>
      )}
      {icon && (
        <Icon hasLabel={!!(label || labelId)} htmlFor={Id || otherProps.id}>
          <FontAwesomeIcon icon={icon} />
        </Icon>
      )}
      {error && showErrorText && (
        <>
          <small
            data-testid='field-mandatory-text'
            className='d-block'
            style={{ color: 'var(--error-color)', margin: '0.5rem 0' }}
          >
            <FormattedMessage id='fieldMandatory' />
          </small>
        </>
      )}
      {input}
    </TextContainer>
  );
}

export default TextInput;
