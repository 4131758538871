import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size: 12px;

  &.dark div {
    color: #fff;
    font-size: 11px;
  }
`;

export const Text = styled.div`
  color: #706d6d;
  text-align: center;
  font-weight: 500;
  line-height: 16px;
  text-transform: capitalize;
`;
